<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    :disabled="isSuperAdmin"
  >
  </v-select>
</template>

<script>
import Storage from "@/services/storage.js";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const locationRepository = RepositoryFactory.get("location");

let storage = new Storage();

export default {
  props: {
    allOption: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
  }),
  computed: {
    isSuperAdmin: function () {
      let user = storage.get("user");

      if (user.user_type !== "superadmin" && user.user_type !== "admin") {
        return true;
      }

      return false;
    },
  },
  methods: {
    getLocations() {
      let params = {
        full_data: true,
      };

      locationRepository
        .get({ params })
        .then((response) => {
          let {
            data: { responseData: items },
          } = response;

          if (this.allOption) {
            this.items = [
              {
                id: null,
                name: "All",
              },
              ...items,
            ];
          } else {
            this.items = items;
          }

          this.setDefaultLocationValue();
        })
        .catch((error) => {
          console.log({ error }, "LocationSelectBox");
        });
    },
    setDefaultLocationValue() {
      this.$emit("setDefaultLocationValue", storage.get("location_id"));
    },
  },
  mounted() {
    this.getLocations();
    this.setDefaultLocationValue();
  },
};
</script>
